<template>
  <div>

  </div>
</template>
<script>
import { getUserInfo } from "../api/daily";

export default {
  components: { },
  data() {
    return {
      user: null,
      code: null,
    };
  },
  created() {
    this.query = this.$route.query;
    let key = "psymini.styxdz.com.cn";
    let appid = "wx0c91c70285339e41";
	
    console.log("appid:" + appid);
    if (key == null || key == undefined) {
      return;
    }

    var code = this.getUrlParam("code");
    var local = local = encodeURIComponent(window.location.href);
	//local = "http://test.styxdz.com.cn/test";
	console.log("lcoal:" + local);
    if (code == null || code == "") {

		
		 window.location.href =          
		 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+appid
		 +'&redirect_uri='+local
		 +'&response_type=code&scope=snsapi_userinfo&state=wecedirect';

    } else {
      console.info("code:" + code);
      this.code = code;
      this.query.code = code;
	  this.query.key = "psymini.styxdz.com.cn";
      this.query.appid = "wx0c91c70285339e41";
	
      getUserInfo(this.query).then((res) => {
        console.info(res);
        if (res == null || res.data == null) {
          return;
        } else {
          this.user = res.data.user;

        }
      });
    }
  },
  methods: {
   
    getUrlParam(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let url = window.location.href.split("#")[0];
      let search = url.split("?")[1];
      if (search) {
        var r = search.substr(0).match(reg);
        if (r !== null) return unescape(r[2]);
        return null;
      } else {
        return null;
      }
    },
   
  },
};
</script>
<style scoped>
.iconfont {
  color: #fff;
}
.float-icon-item {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
.icons-warp {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>
